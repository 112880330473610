<template>
    <b-form class="bg-white shadow-lg rounded p-5">
        <transition name="slide-fade">
            <b-form-group v-if="choice === null" :label="'Was ist ' + event.expression + ' ?'" class="lead">
                <b-form-radio
                    v-for="c in choices"
                    v-model="choice"
                    :value="c[0]"
                    :key="c[0]"
                    class="font-size-base my-2"
                >
                    {{ c[1] }}
                </b-form-radio>
            </b-form-group>
            <div class="d-flex flex-column align-items-center" v-else-if="choice === '0'">
                <p class="lead">Super, {{ event.choices["0"] }} war richtig!</p>
                <i class="fe fe-check-circle text-success display-4"></i>
            </div>
            <div class="d-flex flex-column align-items-center" v-else>
                <p class="lead">Schade, die richtige Antwort war {{ event.choices["0"] }}</p>
                <i class="fe fe-x-circle text-danger display-4"></i>
            </div>
        </transition>
    </b-form>
</template>

<script>
export default {
    name: 'MaphiQuiz',
    props: ['event'],
    data () {
        return {
            choice: null,
        };
    },
    computed: {
        choices () {
            const sorted = Object.entries(this.event.choices)
                .filter(v => !v[1].includes('undefined'))
            ;
            return sorted.sort(() => Math.random() - .5);
        }
    },
    watch: {
        choice (v) {
            setTimeout(() => {
                this.event.resolve();
                this.$emit('done', v);
            }, 2000);
        }
    }
}

</script>


<style scoped>

</style>
