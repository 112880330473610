import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

export const retrieveFromAttend = ({ commit }, { id, courseId }) => {
  commit(types.TOGGLE_LOADING);

  return fetch(`${id}/course/topics`)
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING);
      commit(types.ADD_ITEMS, { id: courseId, items: data['hydra:member'] });
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};
