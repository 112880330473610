export default {
  path: '/account',
  component: () => import('../components/FullscreenLayout'),
  children: [
    {
      path: 'request-password-reset',
      name: 'AccountRequestPasswordReset',
      component: () => import('../views/pages/PasswordForgot')
    },
    {
      path: 'password-reset/:account_id/:token',
      name: 'AccountPasswordReset',
      component: () => import('../views/pages/PasswordReset'),
      props: true
    },
  ]
};
