import * as types from './mutation_types';

export default {
  [types.COURSE_LIST_RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      list: [],
      itemsById: {}
    });
  },

  [types.COURSE_LIST_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.COURSE_LIST_ADD_RETRIEVED](state, retrieved) {
    const items = [ ...state.list, retrieved ];
    items.sort((a, b) => {
      if (a.sort === b.sort) {
        return a.id - b.id
      }
      return a.sort - b.sort;
    });

    Object.assign(state, {
      list: items,
      itemsById: { ...state.itemsById, [retrieved['@id']]: retrieved  }
    });
  },

  [types.COURSE_LIST_TOGGLE_LOADING](state) {
    Object.assign(state, { error: '', isLoading: !state.isLoading });
  },
};
