export default {
  path: '/attend',
  component: () => import('../components/Layout'),
  children: [
    {
      name: 'CourseDashboard',
      path: '/dashboard',
      component: () => import('../views/pages/CourseDashboard'),
      meta: { title: 'Online Kurse' }
    },
    {
      name: 'CourseContent',
      path: ':id/course',
      component: () => import('../views/pages/CourseContent'),
      meta: { title: 'Online Kurs' },
      props: true
    },
    {
      name: 'LessonContent',
      path: ':id/course/topic/:topic_id/lesson/:lesson_id',
      component: () => import('../views/pages/LessonContent'),
      meta: { title: 'Online Kurs' },
      props: true
    },
    {
      name: 'CreateAttends',
      path: '/create-attends',
      component: () => import('../views/pages/CreateAttends'),
      meta: { title: 'Zugangscode einlösen' }
    },
    {
      name: 'Offers',
      path: '/offers',
      component: () => import('../views/pages/Offers'),
      meta: { title: 'Unsere Angebote' }
    },
    {
      name: 'LessonRedirect',
      path: '/:id(\\d+)/:slug',
      component: () => import('../views/pages/LessonRedirect'),
      meta: { title: 'Online Kurs' },
      props: true
    }
  ]
};
