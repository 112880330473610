import * as types from './mutation_types';
import { ISSO_ENTRYPOINT } from "../../../../config/entrypoint";

export const create = ({ commit }, { uri, author, email, text, parent, title = '' }) => {
  commit(types.ISSO_CREATE_SET_ERROR, '');
  commit(types.ISSO_CREATE_TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  return fetch(ISSO_ENTRYPOINT + '/new?uri=' + encodeURIComponent(uri), { method: 'POST', headers: headers, body: JSON.stringify({ email, text, parent, author, title }) })
    .then((response) => {
      commit(types.ISSO_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {

      commit(types.ISSO_CREATE_SET_CREATED, data);
      return data;
    })
    .catch((e) => {
      commit(types.ISSO_CREATE_TOGGLE_LOADING);
      commit(types.ISSO_CREATE_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.ISSO_CREATE_RESET);
};
