import pageRoutes from './pages';
import accountRoutes from './account';
import registerRoutes from './register';
import VueRouter from "vue-router";
import store from "../store";

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('../components/FullscreenLayout'),
      children: [
        {
          name: 'Index',
          path: '',
          component: () => import('../views/pages/Index'),
          meta: { title: 'Online Kurse' }
        }
      ]
    },
    {
      path: '/offering',
      component: () => import('../components/FullscreenLayout'),
      children: [
        {
          name: 'Offering',
          path: '',
          component: () => import('../views/pages/Offering'),
          meta: { title: 'Unsere Kurse' }
        }
      ]
    },
    pageRoutes,
    accountRoutes,
    registerRoutes
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path === '/' || to.path.startsWith('/register') || to.path === '/account/request-password-reset' || to.path.startsWith('/account/password-reset') || to.path.startsWith('/offering')) {
    next();
  }
  else if ( ! store.getters['loginrequest/create/isAuthenticated']) {
    next('/');
  } else {
    next();
  }
});

export default router;
