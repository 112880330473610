const STORAGE_KEY ='studyhelp_online_course_account_id';

export function setAccountId(id) {
  return window.localStorage.setItem(STORAGE_KEY, id);
}

export function getAccountId() {
  return window.localStorage.getItem(STORAGE_KEY);
}

export function removeAccountId() {
  window.localStorage.removeItem(STORAGE_KEY);
}
