<template>
    <b-card no-body class="buddy-videos-tour-block">
        <div class="row justify-content-center mt-5">
            <b-form-radio-group
                :options="options"
                v-model="buddy"
                buttons
                button-variant="outline-secondary"
                class="buddy-videos-tour-switch"
            >
            </b-form-radio-group>
        </div>
        <player-box v-for="video in matched" v-bind:key="video.buddy" :ratio="video.ratio ? video.ratio : '16by9'">
            <iframe :src="video.src" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </player-box>
    </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
    name: "BuddyVideos",
    props: ['buddies', 'videos'],
    data() {
        return {
            buddy: this.videos[0].buddy
        };
    },
    computed: {
        matched() {
            return this.videos.filter(v => this.buddies.indexOf(v.buddy) !== -1 && this.buddy === v.buddy);
        },
        ...mapFields('buddy/list', {
            buddyList: 'items'
        }),
        options() {
            return this.buddyList.map(v => {
                    return {
                        'text': v.name,
                        'value': v['@id']
                    };
                }
            ).filter(v => {
                for (const video of this.videos) {
                    if (v.value === video.buddy) {
                        return true;
                    }
                }
                return false;
            });
        }
    }
}
</script>

<style scoped>

</style>
