import * as types from './mutation_types';

export default {
  [types.CREATEREVIEWREQUEST_CREATE_TOGGLE_LOADING](state) {
    Object.assign(state, { error: '', isLoading: !state.isLoading });
  },

  [types.CREATEREVIEWREQUEST_CREATE_SET_ACKNOWLEDGED](state, acknowledged) {
    Object.assign(state, { acknowledged });
  },

  [types.CREATEREVIEWREQUEST_CREATE_RESET](state) {
    Object.assign(state, {
      acknowledged: false,
      isLoading: false,
    });
  },
};
