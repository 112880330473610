<template>
    <div class="row pt-5 pb-5 justify-content-center">
        <div class="col-md-8 col-sm-10 col-xs-12">
            <div :class="'embed-responsive-' + ratio" class="embed-responsive border card">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlayerBox",
        props: {
            ratio: {
                type: String,
                default: '16by9'
            }
        }
    }
</script>

<style scoped>

</style>
