<template>
    <b-modal
        v-model="show"
        title="Ein kurzes Fazit"
        ok-title="Abschicken"
        ok-only
        @ok="submit"
    >
        <p class="mb-3">Bitte entschuldige die kurze Unterbrechung!<br> Wir würden dich gerne bitten, diesen Onlinekurs einmal zu bewerten und uns eventuell sogar eine kleine Nachricht zu hinterlassen. Damit hilfst du auch künftigen Lernenden, die diesen Kurs nutzen.</p>
        <review-creator v-model="review"></review-creator>
    </b-modal>
</template>

<script>
import ReviewCreator from "./ReviewCreator";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
    name: "ReviewModal",
    components: { ReviewCreator },
    data() {
        return {
            show: true,
            review: {}
        }
    },
    computed: {
        ...mapFields('attend/list', {
            attends: 'itemsById'
        }),
        attend() {
            return this.attends['/attends/' + this.$route.params.id];
        }
    },
    created() {
        this.show = !this.attend.reviewed;
    },
    methods: {
        ...mapActions('createreviewrequest/create', {
            createReview: 'create'
        }),
        submit(e) {
            if (!Object.prototype.hasOwnProperty.call(this.review, 'rating')) {
                e.preventDefault();
            }
            this.createReview({ attend: this.attend['@id'], ...this.review })
                .then(res => {
                    if (res.acknowledged) {
                        this.attends['/attends/' + this.$route.params.id].reviewed = true;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>
