import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

export const getItems = ({ commit }, page = 'states') => {
  commit(types.TOGGLE_LOADING);

  fetch(page, {}, false)
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ITEMS, data['hydra:member']);
      commit(types.SET_VIEW, data['hydra:view']);
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};
