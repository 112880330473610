<template>
    <b-card no-body class="exercise-tabbed-tour-block">
        <div class="card-header">
            <h4 class="card-header-title">{{ title }}</h4>
            <ul class="nav nav-tabs nav-tabs-xl card-header-tabs justify-content-end" role="tablist">
                <li class="nav-item exercise-tabbed-tour-exercise">
                    <a class="nav-link" :class="{ 'active': selected === 0 }" href="#" role="tab" @click.stop.prevent="selected = 0">
                        <span class="fe fe-list"></span>
                    </a>
                </li>
                <li v-show="hasSolution" class="nav-item exercise-tabbed-tour-solution">
                    <a class="nav-link" :class="{ 'active': selected === 1 }" href="#" role="tab" @click.stop.prevent="selected = 1">
                        <span class="fe fe-edit-3"></span>
                    </a>
                </li>
                <li v-show="hasVideo" class="nav-item exercise-tabbed-tour-video">
                    <a class="nav-link" :class="{ 'active': selected === 2 }" href="#" role="tab" @click.stop.prevent="selected = 2">
                        <span class="fe fe-play-circle"></span>
                    </a>
                </li>
            </ul>
        </div>
        <b-card-body>
            <div class="tab-content">
                <div class="tab-pane fade" :class="{ 'show': selected === 0, 'active': active === 0 }" role="tabpanel">
                    <slot name="exercise"></slot>
                </div>
                <div class="tab-pane fade" :class="{ 'show': selected === 1, 'active': active === 1 }" role="tabpanel">
                    <slot name="solution"></slot>
                </div>
                <div class="tab-pane fade" :class="{ 'show': selected === 2, 'active': active === 2 }" role="tabpanel">
                    <slot name="video"></slot>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
    export default {
        name: "ExerciseTabbed",
        props: ['title'],
        data() {
            return {
                selected: 0,
                active: 0
            };
        },
        computed: {
            hasVideo() {
                return !!this.$slots['video'];
            },
            hasSolution() {
                return !!this.$slots['solution'];
            }
        },
        watch: {
            selected(v) {
                setTimeout(() => this.active = v, 100);
            }
        }
    }
</script>

<style scoped>
    .nav-item {
        line-height: 2rem;
        padding-bottom: 0;
    }
    .nav-tabs-xl {
        font-size:1.3rem;
    }
</style>
