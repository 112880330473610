import SubmissionError from '../../../../error/SubmissionError';
import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import { removeToken, setToken } from "../storage";
import { setAccountId } from "../../account/storage";

export const create = ({ commit }, { email, password }) => {
  commit(types.LOGINREQUEST_CREATE_SET_ERROR, '');
  commit(types.LOGINREQUEST_CREATE_TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  return fetch('access_tokens', { method: 'POST', headers: headers, body: JSON.stringify({ email, password }) }, false)
    .then((response) => {
      commit(types.LOGINREQUEST_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {

      setToken(data);
      setAccountId(data['account']);
      commit(types.LOGINREQUEST_CREATE_SET_CREATED, data);
    })
    .catch((e) => {
      commit(types.LOGINREQUEST_CREATE_TOGGLE_LOADING);

      if (e instanceof SubmissionError) {
        commit(types.LOGINREQUEST_CREATE_SET_VIOLATIONS, e.errors);
        // eslint-disable-next-line
        commit(types.LOGINREQUEST_CREATE_SET_ERROR, e.errors._error);

        return;
      }

      commit(types.LOGINREQUEST_CREATE_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  removeToken();
  commit(types.LOGINREQUEST_CREATE_RESET);
};
