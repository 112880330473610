import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

export const retrieve = ({ commit }, id) => {
  commit(types.ATTEND_LIST_TOGGLE_LOADING);

  return fetch(id)
    .then(response => response.json())
    .then((data) => {
      commit(types.ATTEND_LIST_TOGGLE_LOADING);
      commit(types.ATTEND_LIST_ADD_RETRIEVED, data);
      return data;
    })
    .catch((e) => {
      commit(types.ATTEND_LIST_TOGGLE_LOADING);
      commit(types.ATTEND_LIST_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.ATTEND_LIST_RESET);
};
