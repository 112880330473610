import show from './show';
import list from './list';

export default {
  namespaced: true,
  modules: {
    show,
    list,
  },
};
