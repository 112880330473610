<template>
    <div class="card lift" @click="openLink">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-auto">
                    <a :href="link" target="_blank" class="btn btn-sm btn-danger btn-rounded-circle" @click.prevent>
                        <span class="fe fe-download"></span>
                    </a>
                </div>
                <div class="col ms-n2">
                    <h4 class="mb-1">
                        <a :href="link" target="_blank" @click.prevent>{{ linkText }}</a>
                    </h4>
                    <p class="small text-muted mb-0">{{ description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DownloadCard",
    props: ['link', 'linkText', 'description'],
    methods: {
        openLink() {
            window.open(this.link, '_blank');
        }
    }
}
</script>

<style scoped>
.card.lift:hover {
    cursor: pointer;
}
</style>
