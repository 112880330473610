import { ISSO_ENTRYPOINT } from '../../../../config/entrypoint';
import * as types from './mutation_types';

export const getItems = ({ commit }, uri) => {
  commit(types.TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  return fetch(ISSO_ENTRYPOINT + '/?uri=' + encodeURIComponent(uri), { headers: headers })
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ITEMS, data['replies']);
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};
