<template>
    <div class="col-12" ref="container">
        <b-overlay :show="finished || loading || stopped || quiz !== null" class="d-flex col-12">
            <canvas ref="ctx" :height="canvasHeight" :width="canvasWidth" :key="idx"></canvas>
            <template #overlay>
                <div v-if="finished" class="d-flex flex-column justify-content-center align-items-center">
                    <p class="display-3 bg-dark text-white p-3 rounded">Geschafft!</p>
                    <button @click="restart" class="btn btn-large btn-dark mt-2">Nochmal</button>
                </div>
                <button v-else-if="stopped && !loading" @click="start" class="btn btn-dark btn-lg">Übung starten</button>
                <maphi-quiz v-else-if="quiz" :event="quiz" @done="quiz = null"></maphi-quiz>
                <b-spinner v-else large></b-spinner>
            </template>
            <button @click="showTip" class="btn btn-dark" style="position:absolute;top:20px;right:20px;">Hilf mir!</button>
        </b-overlay>
        <slot></slot>
    </div>
</template>
<script>
import { MaphiAlgebraViewer } from 'maphi-algebra-viewer';
import MaphiQuiz from "./MaphiQuiz";

export default {
    name: 'MaphiWindow',
    props: ['canvasHeight', 'hasQuiz'],
    components: { MaphiQuiz },
    data () {
        return {
            loading: false,
            finished: false,
            stopped: true,
            internalExercises: [],
            canvasWidth: 0,
            idx: 0,
            showTip: () => {},
            quiz: null
        };
    },
    beforeDestroy () {
        window.cleanupMaphi();
        window.cleanupMaphi = () => {};
    },
    mounted () {
        this.canvasWidth = this.$refs.container.clientWidth;
    },
    methods: {
        getExercises () {
            return this.$children
                .filter(c => c.$options._componentTag === 'maphi-exercise')
                .map(c => {
                    return {
                        expression: c.$options.propsData.expression,
                        strategy: c.$options.propsData.strategy
                    };
                })
                ;
        },
        start () {
            window.cleanupMaphi();
            this.stopped = false;
            this.internalExercises = this.getExercises();
            this.nextExercise();
        },
        restart () {
            this.internalExercises = this.getExercises();
            this.finished = false;
            this.stopped = false;
            this.nextExercise();
        },
        nextExercise () {
            if (this.internalExercises.length < 1) {
                window.cleanupMaphi = () => {};
                this.finished = true;
                this.$refs.ctx.id = null;
                this.$emit('done');
                return;
            }
            const { expression, strategy } = this.internalExercises.shift();
            this.idx = this.idx + 1;
            this.$nextTick(() => this.goMaphi(expression, strategy));
        },
        goMaphi (expression, strategy) {
            this.loading = true;
            this.$refs.ctx.id = 'canvas';
            MaphiAlgebraViewer()
                .then(async m => {
                    const cleanup = [
                        () => {
                            this.$refs.ctx.id = null;
                            this.$refs.ctx.height = this.canvasHeight;
                            this.$refs.ctx.width = this.canvasWidth;
                            this.internalExercises = this.getExercises();
                            this.stopped = true;
                        }
                    ];
                    try {
                        m['canvas'] = this.$refs.ctx;
                        m.callMain();
                        const id = m.run_window({
                            mode: "expression",
                            interactive: true,
                            padding: 10,
                            expression: expression,
                            strategy: strategy,
                            setShowGuideCallback: callback => {
                                this.showTip = callback;
                                cleanup.push(() => this.showTip = () => {
                                });
                            },
                            ...(typeof this.hasQuiz !== 'undefined' ? {
                                displayQuizCallback: q => this.quiz = q
                            } : {}),
                            finishedCallback: () => setTimeout(this.nextExercise, 2000),
                            transparent: true,
                            functionTranslations: 'gcd:ggT',
                            color: "#12263F",
                        });
                        cleanup.push(() => {
                            m.stop_window(id);
                            m['canvas'] = undefined;
                        })
                    } catch (err) {
                        console.error("caught exception", err);
                        if (typeof err == "number") {
                            console.error(m.getExceptionMessage(err));
                        }
                    }
                    return () => cleanup.reverse().forEach(f => f());
                })
                .then(c => {
                    window.cleanupMaphi = c;
                    this.loading = false;
                })
            ;
        }
    }
}
</script>

<style scoped>

</style>
