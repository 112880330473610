import SubmissionError from '../../../../error/SubmissionError';
import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import { setToken } from "../../loginrequest/storage";
import { setAccountId } from "../../account/storage";
import { LOGINREQUEST_CREATE_SET_CREATED } from "../../loginrequest/create/mutation_types";

export const create = ({ commit }, { email, password }) => {
  commit(types.REGISTRATIONREQUEST_CREATE_SET_ERROR, '');
  commit(types.REGISTRATIONREQUEST_CREATE_SET_VIOLATIONS, {});
  commit(types.REGISTRATIONREQUEST_CREATE_TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  return fetch('/accounts/register', { method: 'POST', headers: headers, body: JSON.stringify({ email, password }) })
    .then((response) => {
      commit(types.REGISTRATIONREQUEST_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      setAccountId(data['accessToken']['account']);
      setToken(data['accessToken']);
      commit('loginrequest/create/' + LOGINREQUEST_CREATE_SET_CREATED, { token: data['accessToken']['token'], validUntil: data['accessToken']['validUntil'] }, { root: true });
      commit(types.REGISTRATIONREQUEST_CREATE_SET_CREATED, data);
    })
    .catch((e) => {
      commit(types.REGISTRATIONREQUEST_CREATE_TOGGLE_LOADING);

      if (e instanceof SubmissionError) {
        commit(types.REGISTRATIONREQUEST_CREATE_SET_VIOLATIONS, e.errors);
        // eslint-disable-next-line
        commit(types.REGISTRATIONREQUEST_CREATE_SET_ERROR, e.errors._error);

        return;
      }

      commit(types.REGISTRATIONREQUEST_CREATE_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.REGISTRATIONREQUEST_CREATE_RESET);
};
