import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

export const create = ({ commit }, { attend, rating, message }) => {
  commit(types.CREATEREVIEWREQUEST_CREATE_TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  return fetch('reviews', { method: 'POST', headers: headers, body: JSON.stringify({ attend, rating, message }) })
    .then((response) => {
      commit(types.CREATEREVIEWREQUEST_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      commit(types.CREATEREVIEWREQUEST_CREATE_SET_ACKNOWLEDGED, data.acknowledged);
      return data;
    })
    .catch(() => {
      commit(types.CREATEREVIEWREQUEST_CREATE_TOGGLE_LOADING);
    });
};

export const reset = ({ commit }) => {
  commit(types.CREATEREVIEWREQUEST_CREATE_RESET);
};
