import { getField, updateField } from 'vuex-map-fields';
import * as actions from './actions';
import mutations from './mutations';
import { getToken } from "../storage";
import moment from "moment";


export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: '',
    created: getToken(),
    violations: null,
  },
  actions,
  getters: {
    getField,
    isAuthenticated: (state) => {
      if (process.env.VUE_APP_IS_SAMPLE) {
        return true;
      }
      return state.created !== null && state.created.token !== null
        && moment(state.created.validUntil).isAfter();
    }
  },
  mutations: {
    updateField,
    ...mutations,
  },
};
