export default {
  path: '/register',
  component: () => import('../components/FullscreenLayout'),
  children: [
    {
      path: '',
      name: 'Register',
      component: () => import('../views/pages/Register')
    }
  ]
};
