<template>
    <download-card
        :link="link"
        link-text="Arbeitsblatt"
        description="zum Herunterladen und Ausdrucken"
    >
    </download-card>
</template>

<script>
import DownloadCard from "@/components/lesson/DownloadCard";

export default {
    name: "Worksheet",
    props: ['link'],
    components: { DownloadCard }
}
</script>

<style scoped>
.card.lift:hover {
    cursor: pointer;
}
</style>
