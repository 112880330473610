<template>
    <b-card no-body class="m-0">
        <b-card-header @click="show = ! show" class="cursor-pointer">
            <b-card-title class="m-0"><slot name="label"></slot></b-card-title>
            <a href="#" class="text-dark" @click.stop.prevent>
                <span v-if="show" class="fe fe-minus"></span>
                <span v-else class="fe fe-plus"></span>
            </a>
        </b-card-header>
        <b-collapse v-model="show">
            <b-card-body>
                <slot></slot>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
    export default {
        name: "Accordion",
        props: ['active'],
        data() {
            return {
                show: this.active === ''
            };
        }
    }
</script>

<style scoped>

</style>
