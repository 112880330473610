<template>
    <div class="row">
        <label class="m-4"><strong>Wie gefällt dir der Kurs von 1 bis 5?</strong></label>
        <b-form-rating v-model="rating" variant="warning" class="m-2 text-center border-0 cursor-pointer"></b-form-rating>
        <b-form-textarea
            v-model="message"
            placeholder="Hinweise, Anmerkungen, ..."
            class="m-4"
        >
        </b-form-textarea>
    </div>
</template>

<script>
export default {
    name: "ReviewCreator",
    props: ['value'],
    data() {
        return {
            rating: null,
            message: null
        }
    },
    watch: {
      rating(v) {
        this.$emit('input', { ...this.value, rating: v });
      },
      message(v) {
        this.$emit('input', { ...this.value, message: v });
      }
    }
}
</script>

<style scoped>

</style>
