const STORAGE_KEY ='studyhelp_online_course_access_token';
const STORAGE_KEY_TOKEN = STORAGE_KEY + '_token';
const STORAGE_KEY_VALID = STORAGE_KEY + '_valid_until';

export function getToken() {
  return {
    token: window.localStorage.getItem(STORAGE_KEY_TOKEN),
    validUntil: window.localStorage.getItem(STORAGE_KEY_VALID),
  }
}

export function setToken(token) {
    window.localStorage.setItem(STORAGE_KEY_TOKEN, token['token']);
    window.localStorage.setItem(STORAGE_KEY_VALID, token['validUntil']);
}

export function removeToken() {
  window.localStorage.removeItem(STORAGE_KEY_TOKEN);
  window.localStorage.removeItem(STORAGE_KEY_VALID);
}
