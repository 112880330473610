import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      itemsByCourse: {},
      view: [],
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.ADD_ITEMS](state, { id, items }) {
    items.sort((a, b) => {
      if (a.sort === b.sort) {
        return a.id - b.id
      }
      return a.sort - b.sort;
    });
    for (const t of items) {
      if (Array.isArray(t['lessons'])) {
        t['lessons'].sort((a, b) => {
          if (a.sort === b.sort) {
            return a.id - b.id;
          }
          return a.sort - b.sort;
        });
      } else {
        t['lessons'] = [];
      }
    }
    Object.assign(state, {
      error: '',
      itemsByCourse: {
        ...state.itemsByCourse,
        [id]: items
      },
    });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

};
