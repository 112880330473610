import * as types from './mutation_types';

function recSort(items) {
  items.sort((a, b) => b['created'] - a['created']);
  for (const i of items) {
    if (i['replies'] && i['replies'].length > 0) {
      recSort(i['replies'])
    }
  }
}

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.SET_ITEMS](state, items) {
    recSort(items);
    Object.assign(state, {
      error: '',
      items,
    });
  },

  [types.ADD_ITEM](state, item) {
    const items = [ ...state.items ];

    if (item['parent']) {
      for (const i of items) {
        if (i['id'] === item['parent']) {
          if ( ! Object.prototype.hasOwnProperty.call(i, 'replies')) {
            i['replies'] = [];
          }
          i['replies'].unshift(item)
        }
      }
    } else {
      items.unshift(item);
    }

    Object.assign(state, {
      error: '',
      items,
    });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
