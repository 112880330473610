<template>
    <div
        class="d-inline-flex align-items-center border-bottom ml-3 mr-3"
        :class="{ 'border-success': isValid, 'border-dark': !isValid }"
    >
        <input
            type="text"
            v-model.trim="guess"
            class="form-control form-control-flush"
            :class="{ 'is-valid': isValid }"
        />
        <i class="fe fe-help-circle cursor-pointer" @click="hint" v-if="!isValid"></i>
    </div>
</template>

<script>
export default {
    name: "CheckedInput",
    props: ["solution"],
    data() {
        return {
            guess: ''
        }
    },
    computed: {
        isValid() {
            return this.guess === this.solution;
        }
    },
    methods: {
        hint() {
            let to = 0;
            while (this.guess[to] === this.solution[to])
                to++;
            this.guess = this.solution.substr(0, to+1);
        }
    }
}
</script>

<style scoped>
</style>
