import * as types from './mutation_types';

export default {
  [types.ISSO_CREATE_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.ISSO_CREATE_TOGGLE_LOADING](state) {
    Object.assign(state, { error: '', isLoading: !state.isLoading });
  },

  [types.ISSO_CREATE_SET_CREATED](state, created) {
    Object.assign(state, { created });
  },

  [types.ISSO_CREATE_RESET](state) {
    Object.assign(state, {
      created: null,
      error: '',
      isLoading: false,
    });
  },
};
