<template>
    <div
        class="d-inline-flex align-items-center border-bottom ml-3 mr-3 p-0"
        :class="{ 'border-success': validation, 'border-dark': validation === null, 'col-4 col-md-2': sized }"
    >
        <b-form-select
            v-model="choice"
            :options="options"
            class="form-control form-control-flush"
            plain
        >
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: "CheckedDropdown",
    props: ['solution', 'other', 'full'],
    data() {
        return {
            choice: null
        }
    },
    computed: {
        validation() {
            return this.choice === this.solution ? true : null;
        },
        options() {
            return [...this.other, this.solution].sort(() => Math.random() - .5);
        },
        sized() {
            return typeof this.full === 'undefined';
        }
    }
}
</script>

<style scoped>

</style>
