const STORAGE_KEY ='studyhelp_online_course_lesson_completed';

export function setCompletion(request) {
  return window.localStorage.setItem(STORAGE_KEY, JSON.stringify(request));
}

export function getCompletion() {
  return JSON.parse(window.localStorage.getItem(STORAGE_KEY));
}

