<template>
    <b-card no-body class="buddy-videos-tour-block">
        <div class="row justify-content-center mt-5">
            <b-form-radio-group
                :options="options"
                v-model="option"
                buttons
                button-variant="outline-secondary"
                class="buddy-videos-tour-switch"
            >
            </b-form-radio-group>
        </div>
        <player-box v-for="video in matched" v-bind:key="video.key">
            <iframe :src="video.src" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </player-box>
    </b-card>
</template>

<script>

export default {
    name: "VideoGroup",
    props: ['videos'],
    data() {
        return {
            option: this.videos[0].key
        };
    },
    computed: {
        options() {
            return this.videos.map(v => v.key);
        },
        matched() {
            return this.videos.filter(v => v.key === this.option);
        }
    }
}
</script>

<style scoped>

</style>
